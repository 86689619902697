import {BsGearWideConnected} from "react-icons/bs"
import CodeLuminisLong from "../Assets/CodeLuminisLong.png"

function App() {
  return (
    <div className="bg-BulbPost h-[100vh] w-[100%] bg-cover bg-no-repeat bg-center flex justify-center items-center">
      <div className="2xl:h-[60%] xl:h-[65%] lg:h-[70%] md:h-[80%] h-[90%] 2xl:w-[60%] xl:w-[70%] lg:w-[80%] w-[90%] bg-[rgb(255,255,255,0.3)] backdrop-blur-[10px] rounded-xl flex justify-center items-center border-[2px] border-solid border-[rgba(225,225,225,0.6)]">
        <div className="bg-[rgba(95,235,144,0)] h-[92%] w-[96%] rounded-xl backdrop-blur-sm border-[2px] border-solid border-[rgba(225,225,225,)] p-6">
          <div className="topWrapper flex flex-col md:flex-row h-[50%]">
            <p className="w-[50%]font-thin text-[19px] sm:text-[20px] md:text-[24px] mt-7 lg:mt-0 lg:pr-20 pr-15 flex justify-center flex-col">
              <span className="text-[34px] text-[#ef327e] font-bold lg:mb-5 mb-0 ">
                Under Construction
              </span>
              Apologies for inconvenience! Website will be up soon! However if
              you have any questions, you can reach us at <a href="mailto:info@codeluminis.com" className="text-blue-600 lg:mt-4 mt-1 text-[22px] underline">info@codeluminis.com</a>
            </p>
            <div className="animationWrapper w-[50%] flex flex-row mt-10 md:mt-0">
              <BsGearWideConnected
                size={120}
                className="animate-spinSlow text-[#FFD700]"
              />
              <BsGearWideConnected
                size={60}
                className="animate-spinReverse text-[#FFD700]"
              />
            </div>
          </div>
          <div className="bottomWrapper sm:mt-[120px] mt-[200px] md:mt-14 lg:mt-12">
            <img src={CodeLuminisLong} alt="longLogo" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
